.logoutModal-container{
    position:absolute;
    top:50%;
    left:50%;
    background:red;
    width: 400px;
    height: 237px;
    transform:translate(-50%,-50%);
    z-index:100000;
    background: #FFFFFF;
    -webkit-animation: fade-in-fwd 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    border-radius: 10px;

}
@-webkit-keyframes fade-in-fwd {
    0% {
 
      opacity: 0;
    }
    100% {
 
      opacity: 1;
    }
  }
  @keyframes fade-in-fwd {
    0% {
     
      opacity: 0;
    }
    100% {
 
      opacity: 1;
    }
  }
.logoutModal-header{
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:36px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom:12px;
    color: #757575;

}

.logoutModal-question{
    color: #757575;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-top:24px;
}

.logoutModal-buttons{
    padding:0px 15px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top:36px;
}

.logoutModal-cancel{
    background:#F8F8F8;
    border:0;
    border-radius: 5px;
    cursor:pointer;

}

.logoutModal-cancel p{
    padding:12px 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#757575;
}
.logoutModal-logout{
    background: #FF3737;
    border:0;
    border-radius: 5px;
    cursor:pointer;
}

.logoutModal-logout p{
    padding:12px 36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#FAFAFA;
}
