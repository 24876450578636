.OnBoarding-container {
  width: 1440px;
  display: flex;
}
@media screen and (max-width: 975px) {
  .OnBoarding-container {
    width: 95%;
    padding-top: 0px;
    margin: 0px auto;
    overflow: hidden;
    height: 100vh;
    overflow-y: auto;
  }
}
