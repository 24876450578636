@keyframes ldio-8f8kukmpldt {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-8f8kukmpldt div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-8f8kukmpldt linear 1s infinite;
    background: #ffffff;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.ldio-8f8kukmpldt div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #ffffff;
  }.ldio-8f8kukmpldt div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #ffffff;
  }
  .loadingio-spinner-spinner-x78lblppll {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    background: none;
    transform: translateY(3px);
  }
  .ldio-8f8kukmpldt {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.34);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-8f8kukmpldt div { box-sizing: content-box; }


  @keyframes ldio-vkul51amp79 {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-vkul51amp79 div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-vkul51amp79 linear 1s infinite;
    background: #8f00ff;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.ldio-vkul51amp79 div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #8f00ff;
  }.ldio-vkul51amp79 div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #8f00ff;
  }
  .loadingio-spinner-spinner-a1zv0ovh0f5 {
    width: 68px;
    height: 68px;
    display: inline-block;
    overflow: hidden;
    background: none;
    
  }
  .ldio-vkul51amp79 {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.68);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-vkul51amp79 div { box-sizing: content-box; }



  @keyframes ldio-223qe3bmf4b {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-223qe3bmf4b div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-223qe3bmf4b linear 1s infinite;
    background: #8f00ff;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.ldio-223qe3bmf4b div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #8f00ff;
  }.ldio-223qe3bmf4b div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #8f00ff;
  }
  .loadingio-spinner-spinner-46245t52ydi {
    width: 41px;
    height: 41px;
    display: inline-block;
    overflow: hidden;
    background: none;
    position: absolute;
    top: 80px;
    left: 47%;

  }
  .ldio-223qe3bmf4b {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.41);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-223qe3bmf4b div { box-sizing: content-box; }


  @keyframes ldio-dnqtnf2h5aw {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-dnqtnf2h5aw div {
    left: 94px;
    top: 48px;
    position: absolute;
    animation: ldio-dnqtnf2h5aw linear 1s infinite;
    background: #808080;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
  }.ldio-dnqtnf2h5aw div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #808080;
  }.ldio-dnqtnf2h5aw div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #808080;
  }
  .loadingio-spinner-spinner-qp3lb0hgo7 {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-dnqtnf2h5aw {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-dnqtnf2h5aw div { box-sizing: content-box; }

  @keyframes ldio-j1upor2783i {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-j1upor2783i div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-j1upor2783i linear 1s infinite;
    background: #ff3737;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.ldio-j1upor2783i div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #ff3737;
  }.ldio-j1upor2783i div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #ff3737;
  }
  .loadingio-spinner-spinner-yef5dlqvhr {
    width: 34px;
    height: 34px;
    display: inline-block;
    overflow: hidden;
    background: none;
    transform: translateY(3px);
  }
  .ldio-j1upor2783i {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.34);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-j1upor2783i div { box-sizing: content-box; }


  @keyframes ldio-wzfu4i4aqfb {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-wzfu4i4aqfb div {
    left: 47px;
    top: 24px;
    position: absolute;
    animation: ldio-wzfu4i4aqfb linear 1s infinite;
    background: #808080;
    width: 6px;
    height: 12px;
    border-radius: 3px / 6px;
    transform-origin: 3px 26px;
  }.ldio-wzfu4i4aqfb div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #808080;
  }.ldio-wzfu4i4aqfb div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #808080;
  }
  .loadingio-spinner-spinner-l876hbozetb {
    width: 68px;
    height: 68px;
    display: inline-block;
    overflow: hidden;
    background: none;
  }
  .ldio-wzfu4i4aqfb {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.68);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-wzfu4i4aqfb div { box-sizing: content-box; }