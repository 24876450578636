.forgot-container {
  margin-left: 223px;
  margin-top: 157px;
}

.forgot-main-text {
  color: #121212;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
}

.forgot-subtext {
  color: #757575;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
  margin-top: 6px;
}

.forgot-form {
  margin-top: 55px;
}

.forgot-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.forgot-form input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  padding-right: 50px;
  font-family: "Qanelas Soft", sans-serif;
}

.forgot-form input::placeholder {
  color: #c4c4c4;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.forgot-button {
  width: 528px;
  height: 60px;
  margin-top: 48px;
  outline: none;
  border: 0;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #757575;
  background: #f8f8f8;
  border-radius: 5px;
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
}

a {
  text-decoration: none;
}

.ForgotToLogin {
  color: var(--primary-color);
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.3px;
  text-align: center;
  margin-top: 60px;
}
.inputBoxForgot {
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
}

@media screen and (max-width: 975px) {
  .forgot-container {
    width: 90vw;
    margin: 0 auto;
    margin-top: 70px;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 40px;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .mobile-nav img {
    width: 112.91px;
  }
  .forgot-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputBoxForgot {
    width: 100%;
    margin-bottom: 25px;
    font-family: "Qanelas Soft";
  }
  .forgot-button {
    width: 100%;
    font-size: 16px;
    height: 52px;
  }
  .ShowforgotPass {
    transform: translate(80vw, 47px);
  }
  .forgot-main-text {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.3px;

    /* black/main */

    color: #121212;
  }
  .forgot-subtext {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;

    /* grey/text */

    color: #757575;
  }
  .forgotToForgot,
  .forgotToSignUp {
    display: none;
  }

  .forgot-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .forgot-form {
    margin-top: 36px;
  }
  .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
  }
  .ForgotToLogin {
    display: none;
  }

  .forgot-to-login {
    margin-top: 36px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
  }
  .forgot-to-signup {
    /* position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%); */

    width: 100%;
    color: #757575;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
  }

  .forgot-form input {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    letter-spacing: -0.3px;
    padding-left: 16px;

    /* black/main */

    color: #121212;
    font-family: "Qanelas Soft", sans-serif;
  }
}

@media screen and (max-height: 940px) {
  .forgot-to-signup {
    margin-top: 48.4svh;
  }
}

@media screen and (max-height: 920px) {
  .forgot-to-signup {
    margin-top: 47.6svh;
  }
}

@media screen and (max-height: 900px) {
  .forgot-to-signup {
    margin-top: 46.8svh;
  }
}
@media screen and (max-height: 880px) {
  .forgot-to-signup {
    margin-top: 45svh;
  }
}
@media screen and (max-height: 860px) {
  .forgot-to-signup {
    margin-top: 43.2svh;
  }
}
@media screen and (max-height: 840px) {
  .forgot-to-signup {
    margin-top: 41.5svh;
  }
}
@media screen and (max-height: 820px) {
  .forgot-to-signup {
    margin-top: 39.6svh;
  }
}
@media screen and (max-height: 800px) {
  .forgot-to-signup {
    margin-top: 38.9svh;
  }
}
@media screen and (max-height: 780px) {
  .forgot-to-signup {
    margin-top: 38.1svh;
  }
}
/* @media screen and (max-height: 770px) {
  .forgot-to-signup {
    margin-top: 40svh;
  }
} */
@media screen and (max-height: 760px) {
  .forgot-to-signup {
    margin-top: 36.5svh;
  }
}
@media screen and (max-height: 740px) {
  .forgot-to-signup {
    margin-top: 34.8svh;
  }
}
@media screen and (max-height: 720px) {
  .forgot-to-signup {
    margin-top: 33svh;
  }
}
@media screen and (max-height: 700px) {
  .forgot-to-signup {
    margin-top: 31.3svh;
  }
}
@media screen and (max-height: 680px) {
  .forgot-to-signup {
    margin-top: 27.7svh;
  }
}
@media screen and (max-height: 660px) {
  .forgot-to-signup {
    margin-top: 28svh;
  }
}
@media screen and (max-height: 640px) {
  .forgot-to-signup {
    margin-top: 26.5svh;
  }
}
/* @media screen and (max-height: 620px) {
  .forgot-to-signup {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
} */
