.success {
  width: auto;
  height: 60px;
  background: #4bb543;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50%);
  transform: translate(-50%, 0%);
  bottom: 40px;
  z-index: 200000;
  padding: 15px;
  /* -webkit-animation: slide-in-bottom 0.5s  both;
    animation: slide-in-bottom 0.5s  both; */

  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
}

.slide-in-bottom {
}
/* ----------------------------------------------
 * Generated by Animista on 2022-12-1 16:29:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    position: absolute;
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    position: absolute;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    position: absolute;
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    position: absolute;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    opacity: 1;
  }
}
.success-text h1 {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.3px;
  color: #ffffff;
}
.success-text p {
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  margin-top: 8px;
}

/* .success-text p:nth-child(2) {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: #757575;
} */

@media screen and (max-width: 830px) {
  .success {
    width: 100vw;
    height: 120px;
    padding-left: 5vw;
    padding-bottom: 16px;
    top: 0;
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

@keyframes errorEnter {
  0% {
    top: -50%;
  }
  100% {
    top: 0%;
  }
}
@keyframes errorLeave {
  0% {
    top: 0%;
  }
  100% {
    top: -50%;
  }
}

@media screen and (max-width: 975px) {
  .success {
    top: -100%;
    animation-name: errorEnter;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position: absolute;
  }
}
