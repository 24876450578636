.policy-container {
    width: 1320px;
    margin: 0px auto 140px auto;
  }
  
  .policy-header-flex{
    display:flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 97px;
  }
  
  
  .policy-download{
    margin-left:36px;
    color:#8f00ff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: -0.3px;
    display:flex;
    align-items: center;
    cursor:pointer;
  }
  
  .policy-download-image{
    width:24px;
    height:24px;
  
  
  }
  
  .policy-download-image{
    margin-top:-7px;
    margin-left:8px;
  }
  
  
  
  
  
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .mt-s {
    margin-top: 24px;
  }
  .mt-l {
    margin-top: 18px;
  }
  
  .ml-i {
    margin-left: 3%;
  }
  
  .circleIcon {
    margin-right: 15px;
  }
  
  .table {
    width: 100%;
    border: 1px solid #757575;
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: left;
  }
  
  .tl {
    border-right: 1px solid #757575;
  }
  
  .policy-main-header {
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    color:#121212;
  }
  
  .purple {
    color: #8f00ff;
  }
  
  .policy-mainText {
    margin-top: 24px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #757575;
  }
  
  .bottom-mainText {
    margin-top: 30px;
  }
  
  .policy-links {
    margin-top: 48px;
    display:flex;
    flex-direction: column;
  }
  
  .policy-link {
    color: #757575;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
  
  .policy-link-color {
    color: #8f00ff;
    cursor: pointer;
    margin-left: 5px;
  }
  
  .policy-one {
    margin-left: 8.5px;
  }
  .policy-link-color:hover {
    text-decoration: underline;
  }
  
  .content-header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 54px;
    margin-top: 88px;
  }
  
  .content-boldText {
    color: #757575;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 54px;
    margin-top: 24px;
    margin-bottom: 0px;
  }
  
  .content-normalText {
    color: #757575;
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }
  
  @media screen and (max-width: 1450px) {
    .policy-container {
      width: 90vw;
    }
  }
  @media screen and (max-width: 1200px) {
  
    .policy-download{
      margin-left:36px;
  
      font-size: 16px;
  
    }
  
    .policy-download img{
      width:16px;
      height:16px;
    }
  
    .policy-main-header,
    .content-header {
      font-size: 38px;
    }
    .policy-mainText,
    .policy-link,
    .content-normalText {
      font-size: 16px;
    }
  
    .content-boldText {
      font-size: 20px;
    }
  
    .bottom-mainText {
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width: 950px) {
    .policy-main-header,
    .content-header {
      font-size: 32px;
    }
    .policy-mainText,
    .policy-link,
    .content-normalText {
      font-size: 14px;
    }
  
    .content-boldText {
      font-size: 16px;
    }
  
    .policy-download{
      margin-left:36px;
  
      font-size: 14px;
  
    }
  
    .policy-download img{
      width:14px;
      height:14px;
    }
  }
  
  @media screen and (max-width: 640px) {
  
    .policy-download{
      margin-left:36px;
  
      font-size: 16px;
  
    }
  
    .policy-download img{
      width:16px;
      height:16px;
    }
    .policy-main-header,
    .content-header {
      font-size: 24px;
    }
    .mt-s {
      margin-top: 14px;
    }
    .mt-l {
      margin-top: 10px;
    }
    .circleIcon {
      margin-right: 10px;
    }
  
    .policy-mainText,
    .policy-link,
    .content-normalText {
      font-size: 16px;
    }
    .content-boldText {
      font-size: 18px;
      margin-top: 14px;
      line-height: 40px;
      margin-bottom: 10px;
    }
  
    .policy-header-flex {
      margin-top: 30px;
    }
  
    .policy-container {
      margin: 0px auto 100px auto;
    }
  
    .top-mainText,
    .bottom-mainText,
    .content-normalText{
      font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 180%;
    letter-spacing: -0.3px;
    }
  
    .policy-link{
      line-height:200%;
      font-size:14px;
    }
  
    .content-header{
      font-size:18px;
      line-height:170%;
    }
  
    .content-boldText{
      font-size:16px;
    }
  
    table {
      font-size: 10px;
      max-width: 90vw;
    }
  }
  
  @media screen and (max-width: 370px) {
    .policy-download{
      margin-left:24px;
  
      font-size: 16px;
  
    }
    .policy-download img{
      width:16px;
      height:16px;
    }
  }
  @media screen and (max-width: 330px) {
    .policy-download{
      margin-left:14px;
  
      font-size: 16px;
  
    }
  
  }
  
  
  @media screen and (max-width: 325px) {
  .policy-header-flex{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .policy-download{
    margin-left:0px;
    margin-top:14px;
  }
  }
  @media screen and (max-width: 350px) {
    table {
      font-size: 8px;
      max-width: 90vw;
    }
  }