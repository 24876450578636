.OnBoardingSidebar-container {
  width: 375px;
  background: #fdfdfd;

  padding-top: 60px;
  padding-left: 60px;
  height: 100vh;
  position: sticky;
  top: 0;
}

.OnBoarding-logo {
  display: flex;
  width: 171px;
  align-items: center;
}

.product-logo {
  margin: auto;
  display: flex;
  align-items: center;
}

.beta {
  background-color: #4bb543;
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 15px;
  padding: 4px 12px;
  border-radius: 40px;
  margin-left: 4px;
}

.product-transactions {
  margin-top: 238px;
  width: 255px;
  height: 100px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  align-items: center;
  position: relative;
}
.product-transactions-image {
  margin-left: 21px;
}
.product-transactions-text {
  align-items: center;
  letter-spacing: -0.3px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #757575;
  top: 22px;
  left: 105px;
  position: absolute;
}
.product-transactions-count {
  color: #121212;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  margin-top: 8px;
}

.Sidebar-text {
  margin-top: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.3px;
  color: #757575;
}

@media screen and (max-width: 975px) {
  .OnBoardingSidebar-container {
    display: none;
  }
}
