.Login-container {
  margin-left: 223px;
  margin-top: 157px;
}
.mobile-nav {
  display: none;
}
.Login-main-text {
  color: #121212;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
  margin-top: 40px;
}

.Login-subtext {
  color: #757575;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
  margin-top: 6px;
}

.Login-form {
  margin-top: 55px;
}
.inputboxLogin {
  font-family: "Qanelas Soft", "sans-serif";
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
  font-size: 14px;
  margin-bottom: 25px;
}

.Login-form input::placeholder {
  color: #c4c4c4;
  font-family: "Qanelas Soft", "sans-serif";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.Login-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.purple {
  color: var(--primary-color);
  text-decoration: underline;
  font-weight: 500;
}

.LoginToForgot {
  display: block;
  margin-top: 36px;
}

.Login-button {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 0;
  margin-top: 48px;
  width: 528px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  color: #757575;
  font-family: "DM Sans";
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
}

.LoginToSignUp {
  display: none;
  text-align: center;
  margin-top: 60px;
}

.ShowLoginPass {
  position: absolute;
  z-index: 10;
  transform: translate(480px, 30px);
  color: #757575;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
}

@media screen and (max-width: 975px) {
  .Login-container {
    width: 90vw;
    margin: 0 auto;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 40px;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .mobile-nav img {
    width: 112.91px;
  }
  .Login-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputboxLogin {
    width: 100%;
    margin-bottom: 25px;
    font-family: "Qanelas Soft", "sans-serif";
  }
  .Login-button {
    width: 100%;
    font-size: 16px;
    height: 52px;
  }
  .ShowLoginPass {
    transform: translate(80vw, 47px);
  }
  .Login-main-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
    margin-top: 0px;
  }
  .Login-subtext {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }
  /* .LoginToForgot,
    .LoginToSignUp{
        display: none;
    } */

  .password-label,
  .email-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .Login-form {
    margin-top: 36px;
  }
  /* .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  } */
  .forgot-mobile {
    display: none !important;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
    display: none;
  }
  .purple {
    text-decoration: none;
    font-weight: 700;
  }
}

input:-webkit-autofill::first-line {
  font-family: "Qanelas Soft", "sans-serif";
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  font-family: "Qanelas Soft", "sans-serif";
}

@media screen and (max-width: 975px) {
  .Login-form {
    /* margin-top:30%;
        height:calc(100vh - 30%); */
    margin-top: 36px;
  }
}

@media screen and (max-width: 575px) {
  .Login-form {
    /* margin-top:30%;
        height:calc(100vh - 30%); */
    margin-top: 36px;
  }
  .Login-container {
    width: 90vw;
    margin: 0 auto;

    overflow-y: hidden;
    margin-top: 70px;
  }
  .Login-container {
    width: 90vw;
    margin: 0 auto;
    overflow-y: hidden;
    margin-top: 70px;
  }

  .Login-main-text {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .Login-subtext {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;
    color: #757575;
  }
  .LoginToForgot {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .Login-main-text {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.3px;
  }
  .Login-subtext {
    margin-top: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    margin-top: 12px;
    color: #757575;
  }
  .LoginToForgot {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .Login-button {
    border-radius: 12px;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
    margin-top: 24px;
  }

  .login-auth {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }

  .login-auth img {
    width: 16px;
    height: 19px;
  }

  .loginauthText {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }

  .Login-container {
    /* position:relative; */
  }

  .login-to-signup {
    /* position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%); */
    margin-top: 32svh;
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;
    letter-spacing: -0.3px;
    width: 100%;
    color: #757575;
  }

  .Login-form input {
    font-family: "Qanelas Soft";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    border-radius: 10px;
  }

  .Login-button img {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-height: 940px) {
  .login-to-signup {
    margin-top: 40dvh;
  }
}

@media screen and (max-height: 920px) {
  .login-to-signup {
    margin-top: 39dvh;
  }
}

@media screen and (max-height: 900px) {
  .login-to-signup {
    margin-top: 38dvh;
  }
}

@media screen and (max-height: 880px) {
  .login-to-signup {
    margin-top: 36dvh;
  }
}

@media screen and (max-height: 860px) {
  .login-to-signup {
    margin-top: 34dvh;
  }
}
@media screen and (max-height: 840px) {
  .login-to-signup {
    margin-top: 32dvh;
  }
}
@media screen and (max-height: 820px) {
  .login-to-signup {
    margin-top: 30dvh;
  }
}
@media screen and (max-height: 800px) {
  .login-to-signup {
    margin-top: 29dvh;
  }
}

@media screen and (max-height: 780px) {
  .login-to-signup {
    margin-top: 28dvh;
  }
}
@media screen and (max-height: 760px) {
  .login-to-signup {
    margin-top: 26dvh;
  }
}
@media screen and (max-height: 740px) {
  .login-to-signup {
    margin-top: 24dvh;
  }
}
@media screen and (max-height: 720px) {
  .login-to-signup {
    margin-top: 22dvh;
  }
}
@media screen and (max-height: 700px) {
  .login-to-signup {
    margin-top: 20dvh;
  }
}
@media screen and (max-height: 680px) {
  .login-to-signup {
    margin-top: 16dvh;
  }
}
@media screen and (max-height: 640px) {
  .login-to-signup {
    margin-top: 14dvh;
  }
}

/* @media screen and (max-height: 620px) {
  .login-to-signup {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
} */
