.q-label{
    font-style: normal;
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.3px;
    color: #757575 !important;
    font-family: "Qanelas Soft", "Sans-serif" !important;

}
.q-input{
    width: 100%;
    height: 48px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    outline: none;
    font-size: 14px;
    margin-top: 6px;
    margin-bottom: 24px;
    padding-left: 10px;
    font-weight: 600;
}
.q-input::placeholder{
    font-size: 14px;
}
.q-button{
    width: 100%;
    height: 48px;
    background: #8f00ff;
    border-radius: 12px;
    outline: none;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.3px;
    border: none;
    color: #FFFFFF;
    margin-top: 12px;
    
}
.q-message{
    background: #FFFCE9;
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: auto;
    margin: 12px 0px 24px 0px;
}
.q-message p{
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    letter-spacing: -0.3px;
    color: #BEA802;
    
}