.success{
    top:-100%;
    animation-name: errorEnter;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    position:absolute;
}


 




@keyframes errorEnter{
    0%{
        top:-50%;
    }
    100%{
        top:0%;
    }
}
@keyframes errorLeave{
    0%{
        top:0%;
    }
    100%{
        top:-50%;
    }
}
