.dashboard-home-container {
  padding: 40px 5% 68px 48px;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 2000;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-22 21:34:11
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-fwd
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in-fwd {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.info-center {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #121212;
}

.dashboard-home-navbar {
  display: flex;
  justify-content: space-between;
}

.dashboard-home-navbar-left p:nth-child(1) {
  color: var(--black);
  font-size: 20px;
  line-height: 31px;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.dashboard-home-navbar-left p:nth-child(2) {
  color: var(--default-links);
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  letter-spacing: -0.3px;
}

.dashboard-home-navbar-right {
  position: relative;
  display: flex;
}

.dashboard-home-navbar-right .inputlap {
  padding-left: 60px;
  align-self: center;
  width: 315px;
  height: 45px;
  border-radius: 5px;
  background: #f8f8f8;
  outline: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  color: var(--default-links);
  font-weight: 400;
  border-radius: 10px;
  transition: background 300ms ease;
}

.dashboard-home-navbar-right input::placeholder {
  color: #c4c4c4;
}

.dashboard-home-navbar-right input:focus {
  background: #fff;
  transition: background 300ms ease;
  /* border:1px solid #C4C4C4; */
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25),
    0px 1px 1px rgba(32, 32, 32, 0.05);
}

.dashboard-home-navbar-right .search {
  position: absolute;
  z-index: 7000;
  top: 18px;
  left: 24px;
}

.dashboard-home-navbar-right-image1 {
  align-self: center;
  border: 1px solid #c4c4c4;
  height: 45px;
  width: 45px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
}

.roll {
  animation: App-logo-spin infinite 1s linear;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.dashboard-home-navbar-right-image2 {
  align-self: center;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background: #fff1f1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
}

.dashboard-home-accountDetails {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 60px;
  gap: 20px;
}

.dashboard-home-boxes-container {
  display: flex;
}

.dashboard-home-transactions-list span {
  background-color: #8f00ff;
  color: wheat;
  padding: 5px 10px;
  transform: translateX(50px);
  border-radius: 5px;
}

.dashboard-home-accountDetails-boxes:nth-child(1) {
  width: 50%;
  height: 100px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  background: var(--primary-color);
  display: flex;
  justify-content: space-between;

  padding: 22.75px 0px 0px 26px;
  border: none;
}

.dashboard-home-accountDetails-boxes:nth-child(1) img:nth-child(1) {
  margin-right: 20px;
}

.dashboard-home-accountDetails-copy img {
  transform: translateY(16px);
}

.dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(1) {
  color: white;
  font-size: 18px;
  margin-bottom: 5px;
}

.dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(2) {
  color: white;
  font-size: 14px;
}

.dashboard-home-accountDetails-boxes {
  padding: 22.75px 0px 0px 26px;
  width: 50%;
  height: 100px;

  border: 1px solid #c4c4c4;
  border-radius: 10px;
}

.dashboard-home-accountDetails-boxes img {
  margin-right: 20px;
}

.dashboard-home-accountDetails-details p:nth-child(1) {
  color: var(--default-links);
  font-size: 12px;
  margin-bottom: 10px;
}

.dashboard-home-accountDetails-details p:nth-child(2) {
  color: var(--black);
  font-size: 18px;
}

.dashboard-home-transactions {
  height: 50vh;
  position: relative;
  /* overflow-y: auto; */
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(32, 32, 32, 0.05);
    border-radius: 5px; */
}

.dashboard-home-transaction-nav {
  display: flex;
  justify-content: space-between;
  margin: 32.5px 0px 52px 0px;
}

.dashboard-home-transaction-nav-heading {
  display: flex;
}

.dashboard-home-transaction-nav p {
  color: var(--default-links);
}

.dashboard-home-transaction-nav-heading p {
  color: var(--black);
  font-size: 15px;
}

.dashboard-home-transaction-nav-heading img {
  margin-right: 18px;
}

.dashboard-home-fetch-flex:nth-child(1) {
  border-top: none;
}

.dashboard-home-fetch-flex {
  border-top: 1px solid #efefef;
  cursor: pointer;
  transition: background 300ms ease-in-out;

  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 0px 24px;
}

.dashboard-home-fetch-flex:hover {
  background: #f8f8f8;
  transition: background 300ms ease-in-out;
}

.dashboard-home-transactions-list {
  /* overflow-y: auto; */
}

.dashboard-home-transaction-header-flex {
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 24px 24px 13px 24px;
  margin-bottom: 0px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background: #f8f8f8;
}

.dashboard-home-transaction-header-flex p:nth-child(1) {
  color: #757575;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 10vw;
}

.dashboard-home-transaction-header-flex p:nth-child(2) {
  color: #757575;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 18vw;
}

.dashboard-home-transaction-header-flex p:nth-child(3) {
  color: #757575;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 10vw;
}

.dashboard-home-transaction-header-flex p:nth-child(4) {
  color: #757575;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 18vw;
}

/* .refreshbar{
    color:red;
    font-size:100px;
    position:absolute;
    top:0;
    left:0;
    width:0%;
    background:var(--primary-color);
    height:4px;
    animation-name: loader-bar;
    animation-duration: .5s;
    position:absolute;
    top:0;
    z-index:10000;

} */

@keyframes loader-bar {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.refreshbar {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  overflow: hidden;
}

.refreshbar::after {
  content: "";
  width: 10%;
  height: 4px;
  background: #4bb543;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  animation: animloader 0.9s linear infinite;
}

@keyframes animloader {
  0% {
    left: 0;
    transform: translateX(-100%);
    width: 10%;
  }

  50% {
    width: 40%;
  }

  100% {
    left: 100%;
    transform: translateX(0%);
    width: 10%;
  }
}

.pending {
  width: 40px;
}

.preload {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.error {
  color: red;
  font-size: 14px;
}

.dashboard-home-copied {
  font-size: 14px;
  background: var(--default-links);
  color: white;
  border-radius: 50px;
  width: 200px;
  height: 43px;
  position: absolute;
  z-index: 10000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5%;
}

.dashboard-home-copied p {
  margin-left: 0px;
}

.dashboard-home-accountDetails img {
  cursor: pointer;
}

.fade-out {
  -webkit-animation: fade-out 1.5s ease-out both;
  animation: fade-out 1.5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-23 8:46:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-23 8:46:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2022-7-23 8:49:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cover {
  position: absolute;

  height: 100vh;
  left: 0%;
  top: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.4);
  width: 200vw;
}

.coverrefresh {
  position: absolute;

  height: 100vh;
  left: 0%;
  top: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  background-color: white;
  opacity: 0.7;
  width: 200vw;
}

.refund-mobile {
  display: none;
}

.cover2 {
  position: absolute;

  background-color: rgba(255, 255, 255, 0.5);
  height: 105vh;
  left: 0%;
  top: 50%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
  width: 200vw;
  z-index: 8000;
}

#modalodd {
  background-color: #ffffff;
  z-index: 8000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  width: 560px;
  height: 450px;
  -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.modal {
  background-color: #ffffff;

  position: absolute;
  z-index: 8000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  border-radius: 10px;
  width: 560px;
  height: 530px;
  -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.dashboard-transaction-modal-heading {
  padding-bottom: 12px;
  padding-top: 36px;
  border-bottom: 1px solid #efefef;
}

.dashboard-transaction-modal-heading p {
  text-align: center;
  font-size: 18px;
  color: var(--default-links);
}

.dashboard-modal-flex-container {
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 36px;
}

.dashboard-modal-flex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}

.dashboard-modal-flex-refund {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.dashboard-modal-flex-refund p {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  display: flex;
  align-items: center;
  letter-spacing: -0.3px;

  color: #757575;
}

.dashboard-modal-flex-container-refund {
  margin: 35px 36px;
}

.dashboard-modal-flex:last-child {
  margin-bottom: 40px;
}

.dashboard-modal-flex p {
  font-weight: 500;
  font-size: 13px;
  color: #757575;
}

.dashboard-modal-buttons {
  display: flex;
  justify-content: space-between;
  padding-left: 36px;
  padding-right: 36px;
  position: absolute;
  bottom: 30px;
  width: 100%;
}

.dashboard-modal-warning {
  background: #fffdec;
  margin-left: 36px;
  margin-right: 36px;
  border-radius: 5px;
  margin-top: 25px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dashboard-modal-warning p {
  font-size: 12px;
  color: #eed202;
  padding: 10px;
}

.form-refunds {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 30px;
  width: 87%;
}

.dashboard-copied img {
  width: 20px;
  display: inline;
}

.refund-fade {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #f8f8f8;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: var(--default-links);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.form-refunds button:nth-child(1) {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #f8f8f8;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: var(--default-links);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.form-refunds button:nth-child(2) {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #8f00ff;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.refund-show {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #8f00ff;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.dashboard-modal-buttons button:nth-child(1) {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #f8f8f8;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: var(--default-links);
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.dashboard-modal-buttons button:nth-child(2) {
  padding: 12px 36px;
  gap: 10px;
  width: 121px;
  height: 45px;
  background: #8f00ff;
  border-radius: 5px;
  border: none;
  font-family: "Poppins", sans-serif;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
}

.dashboard-modal-refund-input {
  margin-left: 36px;
  margin-right: 36px;
  margin-top: -10px;
  margin-bottom: 20px;
}

.dashboard-modal-refund-input label {
  display: block;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: -0.3px;
  color: #757575;
}

.dashboard-modal-refund-input input {
  width: 100%;
  height: 38px;
  border: 1px solid #efefef;
  border-radius: 5px;
  margin-top: 20px;
  outline-color: var(--primary-color);
  outline-width: 0.3px;
  margin-bottom: 10px;
  font-family: "Poppins", sans-serif;
  padding-left: 20px;
}

.rotate {
  animation: rotate infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(360);
  }
}

.inputmobile,
.searchmobile {
  display: none;
}

.dashboard-home-transactions-list:nth-child(1) {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--default-links);
  height: 53px;
}

.dashboard-home-transactions-list:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--default-links);
  height: 53px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboard-home-transactions-list:nth-child(2) p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* .dashboard-home-transactions-list:nth-child(2)::after {
    content: "...";

    top: 0;
    right: 0;
    color: red;
}
 */

.dashboard-home-transactions-list:nth-child(3) {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 10vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--default-links);
  height: 53px;
}

.dashboard-home-transactions-list:nth-child(4) {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  width: 18vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--default-links);
  height: 53px;
}

@media screen and (max-width: 1415px) {
  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(1) {
    font-size: 14px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(2) {
    font-size: 12px;
  }

  .dashboard-home-accountDetails-details {
    height: 57px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .dashboard-home-accountDetails-details p:nth-child(2) {
    font-size: 14px;
  }
}

@media screen and (max-width: 1320px) {
  .copy-icon {
    width: 12px;
  }
}

@media screen and (max-width: 1310px) {
  .dashboard-home-accountDetails-boxes img {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1430px) {
  .dashboard-home-accountDetails-boxes:nth-child(1) {
    padding: 22.75px 0px 0px 16px;
  }

  .dashboard-home-accountDetails-boxes {
    padding: 22.75px 0px 0px 16px;
  }

  .dashboard-home-accountDetails-boxes img {
    margin-right: 10px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) img:nth-child(1) {
    margin-right: 10px;
  }
}

@media screen and (max-width: 1160px) {
  .dashboard-home-accountDetails-boxes img {
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) img:nth-child(1) {
  }

  .dashImg {
    width: 40px;
    margin-top: 10px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(2) {
    font-size: 10px;
  }
}

@media screen and (max-width: 975px) {
  .dashImg {
    width: 50px;
    margin-top: 0px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(2) {
    font-size: 12px;
  }

  .dashboard-home-accountDetails-boxes img {
    margin-right: 20px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) img:nth-child(1) {
    margin-right: 20px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) {
    margin: 0px 0px 0px 5vw !important;
    padding: 22.75px 0px 0px 26px;
  }

  .dashboard-home-accountDetails-boxes {
    padding: 22.75px 0px 0px 26px;
  }

  .copy-icon {
    width: 22px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(1) {
    font-size: 18px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) p:nth-child(2) {
    font-size: 14px;
  }

  .dashboard-home-accountDetails-details {
    height: 57px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .dashboard-home-accountDetails-details p:nth-child(2) {
    font-size: 18px;
  }

  .dashboard-home-accountDetails-boxes img {
    margin-right: 20px;
  }

  .dashboard-home-transaction-header-flex p:nth-child(1) {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
  }

  .dashboard-home-transaction-header-flex p:nth-child(2) {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
  }

  .dashboard-home-transaction-header-flex p:nth-child(3) {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
    text-align: right;
  }

  .dashboard-home-transaction-header-flex p:nth-child(4) {
    color: #c4c4c4;
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
  }

  .dashboard-home-transactions-list:nth-child(1) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--default-links);
    height: 53px;
  }

  .dashboard-home-transactions-list:nth-child(2) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--default-links);
    height: 53px;
  }

  .dashboard-home-transactions-list:nth-child(3) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--default-links);
    height: 53px;
  }

  .dashboard-home-transactions-list:nth-child(4) {
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--default-links);
    height: 53px;
  }

  .dashboard-home-container {
    margin: 0;
    height: 100vh;
    padding: 0;

    position: fixed;
    z-index: 100;
  }

  .dashboard-home-navbar {
    height: 85px;
    background-color: white;
    width: 100vw;
    z-index: 7000;
  }

  .dashboard-home-navbar-left p:nth-child(1) {
    position: absolute;
    top: 41px;
    left: 15vw;

    font-size: 16px;

    letter-spacing: -0.3px;

    color: #121212;
  }

  .dashboard-home-navbar-left p:nth-child(2) {
    display: none;
  }

  .dashboard-home-navbar-right .inputlap {
    display: none;
  }

  .dashboard-home-navbar-right .search {
    display: none;
  }

  .dashboard-home-navbar-right-image1 {
    border: none;

    margin-right: 10px;
  }

  .dashboard-home-accountDetails {
    width: 100vw;
    overflow-x: auto;
    display: grid;
    grid-template-columns: repeat(4, auto);
    justify-content: space-between;
    margin-top: 20px;
  }

  .dashboard-home-accountDetails-boxes {
    width: 85vw;
    margin: 0px 15px 0px 0px;
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) {
    width: 85vw;
    margin-right: 15px;
  }

  .dashboard-home-transaction-header-flex {
    width: 100%;

    padding: 24px 24px 13px 24px;
    margin-bottom: 0px;
    background: #f8f8f8;
  }

  .dashboard-home-transaction-header-flex p:nth-child(1),
  .dashboard-home-transaction-header-flex p:nth-child(4),
  .dashboard-home-transaction-header-flex p:nth-child(5) {
    display: none;
  }

  .dashboard-home-transactions-list {
    padding: 0;
  }

  .dashboard-home-transaction-header-flex p {
    color: #757575;
  }

  .dashboard-home-transactions-list:nth-child(2) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 500;
  }

  .dashboard-home-transactions-list:nth-child(3) p {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    font-weight: 600;
  }

  .dashboard-home-fetch-flex {
    width: 100%;
    padding: 0;
  }

  .dashboard-home-fetch-flex .dashboard-home-transactions-list {
    width: 50%;
    padding: 0px 24px;
  }

  .dashboard-home-fetch-flex .dashboard-home-transactions-list:nth-child(2) p {
    width: 100%;
    text-align: left;
    font-size: 14px;
  }

  .dashboard-home-fetch-flex .dashboard-home-transactions-list:nth-child(3) p {
    width: 100%;
    text-align: right;
    font-size: 14px;
  }

  .dashboard-home-fetch-flex .dashboard-home-transactions-list:nth-child(1),
  .dashboard-home-fetch-flex .dashboard-home-transactions-list:nth-child(4),
  .dashboard-home-fetch-flex .dashboard-home-transactions-list:nth-child(5) {
    display: none;
  }

  .dashboard-modal-flex-container {
    padding: 24px 24px 0px 24px;
  }

  .dashboard-modal-buttons {
    padding: 0px 24px;
  }

  .dashboard-modal-buttons button:nth-child(2) {
    display: none;
  }

  .refund-mobile {
    display: block;
    padding: 0;
    gap: 10px;
    width: 90px;
    height: 45px;
    background: #8f00ff;
    border-radius: 5px;
    border: none;
    font-family: "Poppins", sans-serif;
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }

  .dashboard-modal-buttons button:nth-child(1) {
    padding: 0;
    width: 90px;
  }

  .dashboard-home-transactions {
    padding: 0;
    height: 50%;
    overflow-y: hidden;
    width: 90vw;
    margin: 20px auto 0px auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    box-shadow: none;
  }

  .dashboard-home-boxes-container {
    margin-right: 12px;
  }

  .dashboard-home-transaction-nav {
    width: 90vw;
    margin: 35px auto 25px auto;
  }

  .dashboard-home-transaction-nav p:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.3px;

    /* black/main */

    color: #121212;
  }

  .dashboard-home-transaction-nav .home-text-none {
    display: none;
  }

  .cover {
    height: 200vh;
    width: 200vw;

    top: 0;
    right: 0;
    z-index: 7000;
  }

  .modalmobile {
    position: absolute;
    z-index: 80000;
    height: 125%;
    overflow-y: auto;
    width: 100%;
    background-color: white;
    padding-bottom: 10px;
  }

  #modalodd {
    position: fixed;
    width: 324px;
    height: 440px;
    left: calc(162px + ((100% - 324px) / 2));
    top: 50%;
  }

  .modal {
    width: 324px;
    height: 494px;
    left: calc((100% - 324px) / 2);
    top: calc((110% - 494px) / 2);
  }

  .dashboard-modal-flex p:nth-child(1) {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .dashboard-modal-flex p:nth-child(2) {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .modalmobile .dashboard-transaction-modal-heading {
    border: none;
    margin: 0px 24px;
  }

  #modalodd .dashboard-transaction-modal-heading p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .modalmobile .dashboard-transaction-modal-heading p {
    margin-top: 36px;
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .dashboard-modal-warning {
    background: #fffdec;
    margin-left: 24px;
    margin-right: 24px;
    border-radius: 5px;
    margin-top: 25px;
    height: 121px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .dashboard-modal-warning p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;

    /* safety/main */

    color: #bea802;
  }

  .dashboard-modal-refund-input {
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 20px;
    margin-bottom: 48px;
  }

  .dashboard-modal-flex-container-refund {
    margin: 40px 24px;
  }

  .dashboard-modal-flex-container-refund p {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
  }

  .dashboard-home-copied {
    position: absolute;
    top: 30px;
    left: calc((100% - 210px) / 2);
  }

  .dashboard-home-accountDetails-boxes:nth-child(1) {
    margin: 0px 15px;
  }

  .dashboard-home-accountDetails-boxes {
    margin-right: 15px;
  }

  .modalmobile label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
    margin-bottom: 12px;
  }

  .modalmobile input {
    margin: 0;
  }

  .modalmobile input:nth-child(2) {
    margin-bottom: 35px;
  }

  .form-mobile button {
    margin-top: 50px;
    color: black;
    font-family: "Poppins", sans-serif;
    width: 100%;
    height: 45px;
    background: #f8f8f8;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #757575;
    border: none;
  }

  .mobilewarning {
    font-weight: 500;
    position: absolute;
    transform: translateY(-25px);
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #ff3737;
  }

  .dashboard-home-navbar-right input:focus {
    background: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #d9d9d9;
  }

  .dashboard-home-navbar-right {
    position: absolute;
    right: 10px;
    top: 35px;
  }

  .inputmobile {
    font-size: 14px;
    display: block;
    border-right: none;
    border-top: none;
    border-left: none;
    outline: none;
    border-bottom: 1.2px solid #d9d9d9;
    font-family: "Poppins";
    width: 80vw;
    right: calc((100vw - 90vw) / 2);
    position: absolute;
    -webkit-animation: 2s move linear;
    animation: 0.2s move linear;
    background-color: transparent;
    border-radius: none;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-8-26 23:45:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
  @-webkit-keyframes move {
    0% {
      width: 0px;
      -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    100% {
      -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  @keyframes move {
    0% {
      width: 0px;
      -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    100% {
      -webkit-transition: cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }

  .searchmobile {
    display: block;
    width: 20px;
  }

  .mobilesearchdiv {
    position: absolute;
  }

  .mobilesearchdiv img {
    position: absolute;
    right: 90vw;
    top: 3px;
  }

  .dashboard-home-fetch-flex:hover {
    background: none;
    transition: none;
  }

  .info-center {
    font-size: 11px;
    top: 50%;
  }
}

@media screen and (max-width: 271px) {
  .dashboard-home-container {
    zoom: 0.8;
  }
}
