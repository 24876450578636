.kyc-dash-box{
    width: 100%;
    height: 41px;
    
    border-radius: 5px;
    background-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 24px 0px 36px 0px;
    height:41px;
    background-image: url("/public/assets/dashed-border.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    cursor:pointer;
}

.kyc-dash-box p{
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */
   
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    
    /* icon */
    
    color: #C4C4C4;
    
}
