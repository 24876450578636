.reset-container {
  margin-left: 223px;
  margin-top: 157px;
}
.inputBoxReset {
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
  margin-bottom: 25px;
}
.reset-main-text {
  letter-spacing: -0.3px;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

.reset-subtext {
  color: #757575;
  letter-spacing: -0.3px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  margin-top: 4px;
}

.reset-form input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  font-family: "Poppins", sans-serif;
}
.reset-form input::placeholder {
  color: #c4c4c4;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.reset-form {
  margin-top: 55px;
}

.ShowPass {
  position: absolute;
  z-index: 10;
  transform: translate(195px, 30px);
  color: #757575;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  cursor: pointer;
}

.reset-form input:focus {
  outline: 1px solid var(--primary-color);
  border: none;
}

.reset-form input {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  position: relative;
  padding-right: 50px;
}

.Reset-button {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 0;
  margin-top: 48px;
  width: 528px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  color: #757575;
  font-family: "DM Sans";
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
}

.resetpassError {
  color: red;
  font-size: 12px;
  width: 244px;
  margin-top: 5px;
  position: absolute;
  transform: translateY(-25px);
}
@media screen and (max-width: 975px) {
  .reset-container {
    width: 90vw;
    margin: 0 auto;
    margin-top: 0px;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 55px;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .reset-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputBoxReset {
    width: 100%;
    margin-bottom: 25px;
    font-family: "Poppins";
  }
  .reset-button {
    width: 100%;
    font-size: 16px;
    height: 52px;
  }
  .ShowresetPass {
    transform: translate(80vw, 47px);
  }
  .reset-main-text {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .reset-subtext {
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }
  .resetToForgot,
  .resetToreset {
    display: none;
  }

  /* .password-label,
    .email-label{
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: #121212;
    } */
  .reset-form {
    margin-top: 36px;
  }
  .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
  }
  .reset-password-area {
    width: 100%;

    flex-direction: column;
  }
  .reset-password-area label {
    width: 100%;
    position: relative;
  }
  .passwordBox {
    width: 100%;
    margin-bottom: 25px;
  }
  .password-label {
    margin-top: 0px;
  }
  .reset-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }

  .label-sign {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .ShowPass-sign {
    width: 100%;
    position: absolute;
    transform: translate(90%, 48px);
  }
  .resetToLogin {
    display: none;
  }
  .reset-subtext {
    display: none;
  }
  .policy {
    display: none;
  }
  .emailError {
    transform: translateY(-25px);
  }
  .passError {
    transform: translateY(-25px);
  }
  .Reset-button {
    width: 100%;
    font-size: 16px;
  }

  .reset-form input {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    letter-spacing: -0.3px;
    padding-left: 16px;

    /* black/main */

    color: #121212;
    font-family: "Qanelas Soft", sans-serif;
  }
  .reset-container {
    margin-top: 0px;
    font-family: "Qanelas Soft", sans-serif !important;
  }
}

.reset-to-signup {
  /* position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%); */
  margin-top: 32svh;
  font-family: "Qanelas Soft";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.3px;
  width: 100%;
  color: #757575;
}

@media screen and (max-height: 940px) {
  .reset-to-signup {
    margin-top: 38.6svh;
  }
}

@media screen and (max-height: 920px) {
  .reset-to-signup {
    margin-top: 37.7svh;
  }
}

@media screen and (max-height: 900px) {
  .reset-to-signup {
    margin-top: 36.6svh;
  }
}

@media screen and (max-height: 880px) {
  .reset-to-signup {
    margin-top: 34.6svh;
  }
}
/* @media screen and (max-height: 870px) {
  .reset-to-signup {
    margin-top: 35svh;
  }
} */

@media screen and (max-height: 860px) {
  .reset-to-signup {
    margin-top: 32.5svh;
  }
}
@media screen and (max-height: 840px) {
  .reset-to-signup {
    margin-top: 30.5svh;
  }
}
@media screen and (max-height: 820px) {
  .reset-to-signup {
    margin-top: 28.5svh;
  }
}
@media screen and (max-height: 800px) {
  .reset-to-signup {
    margin-top: 27.4svh;
  }
}

@media screen and (max-height: 780px) {
  .reset-to-signup {
    margin-top: 26.3svh;
  }
}
@media screen and (max-height: 760px) {
  .reset-to-signup {
    margin-top: 24.4svh;
  }
}
@media screen and (max-height: 740px) {
  .reset-to-signup {
    margin-top: 22.3svh;
  }
}
@media screen and (max-height: 720px) {
  .reset-to-signup {
    margin-top: 20.2svh;
  }
}
@media screen and (max-height: 700px) {
  .reset-to-signup {
    margin-top: 18.2svh;
  }
}
@media screen and (max-height: 680px) {
  .reset-to-signup {
    margin-top: 14.2svh;
  }
}
@media screen and (max-height: 660px) {
  .reset-to-signup {
    margin-top: 14.1svh;
  }
}
@media screen and (max-height: 640px) {
  .reset-to-signup {
    margin-top: 12.1svh;
  }
}

/* @media screen and (max-height: 620px) {
  .reset-to-signup {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
} */
