.mobile-navigation{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    width: 100vw;
    position: absolute;
    left: 0;
    transform: translateY(41px);
    padding: 0px 5%;
}
.mobile-tab{
    
    padding-bottom: 8px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #8f00ff;
    transform: translateY(1px);
}
.mobile-tab p{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: #757575;
}
.mobile-tab h2{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  
    color: #4BB543;
}
.mobile-tab div{
    width: 28px;
    height: 19px;
    background: #F2FAF1;
    border-radius: 100px;
    display: grid;
    place-items: center;
    transform: translate(3px , 2px );
}
.absolute-pop{
    position: absolute;
    right: 17px;
    bottom: 15vh ;
}
.invites-mid{
    margin: 36px 0px;
}
.invites-mid-intro{
    font-weight: 500;
font-size: 18px;
line-height: 22px;
letter-spacing: -0.3px;
color: #757575;
margin-bottom: 24px;
}

.spin{
    -webkit-animation: spin 0.9s linear infinite;
  animation: spin 0.9s linear infinite;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(360deg); }
    100% { -webkit-transform: rotate(0deg); }
  }
  
  @keyframes spin {
    0% { -webkit-transform: rotate(360deg); }
    100% { -webkit-transform: rotate(0deg); }
  }



