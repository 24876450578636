.dashboard-settings-container{
    padding: 40px 5% 68px 48px;
    width: 100%;
    position: relative;
    z-index: 2000;
    height: 100vh;
    
}

.dashboard-settings-navbar{
    display: flex;
    justify-content: space-between;
}

.dashboard-settings-navbar-left p:nth-child(1){
    color: var(--black);
    font-size: 20px;
    line-height: 31px;
    font-weight: 500;
    letter-spacing: -0.3px;
}
.dashboard-settings-navbar-left p:nth-child(2){
    color: var(--default-links);
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: -0.3px;
}

.settings-nav{
    margin-top:60px;
    display:flex;

}



.settings-border-bottom{
    border-bottom:1px solid #F8F8F8;
    margin-left:-48px;
    width:100%;
    /* width:100vw; */
}

.settings-nav p{
    font-size:15px;
    color:#C4C4C4;
    cursor:pointer;
    transition:color 250ms ease-in-out;
}

.settings-profile{
    
}


.settings-Security{
   
}




.setting-flex{
    display:flex;
    flex-direction: column;
}
.settings-line{
    width: 30px;
    height: 2px;
    background: var(--primary-color);
    display:flex;
    align-items: center;
    justify-content: center;
    margin-left:50%;
    transform:translateX(-50%);
    margin-top:12px;
    border-radius: 20px;
}





.setting-payouts-form{
    margin-top:0px !important;
}

