.otp-container {
  margin-left: 223px;
  margin-top: 157px;
}
.inputBoxOtp {
  width: 528px;
  height: 50px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding-left: 24px;
  margin-top: 11px;
  font-family: "Poppins";
}
.otp-main-text {
  color: #121212;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 39px;
}

.otp-subtext {
  color: #757575;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: -0.3px;
  margin-top: 6px;
}

.otp-button {
  background-color: #f8f8f8;
  border-radius: 5px;
  border: 0;
  margin-top: 48px;
  width: 528px;
  height: 60px;
  font-size: 18px;
  font-weight: 700;
  color: #757575;
  font-family: "DM Sans";
  transition: color 100ms ease-in;
  transition: background 100ms ease-in;
}
.otp-button img {
  transform: translateY(3px);
  width: 25px;
}

.otpToSignUp {
  text-align: center;
  margin-top: 160px;
}

@media screen and (max-width: 975px) {
  .otp-container {
    width: 90vw;
    margin: 70px auto 0px auto;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    margin-top: 40px;
  }
  .mobile-nav a {
    align-self: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.3px;
    color: #8f00ff;
  }
  .mobile-nav img {
    width: 112.91px;
  }
  .otp-form input:focus {
    outline: none;
    border: 1px solid #c4c4c4;
  }
  .inputBoxOtp {
    width: 100%;
    margin-bottom: 25px;
    font-family: "Poppins";
  }
  .otp-button {
    width: 100%;
    border-radius: 12px;
    height: 54px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
  .ShowotpPass {
    transform: translate(80vw, 47px);
  }
  .otp-main-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .otp-subtext {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #757575;
  }

  .password-label,
  .email-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    color: #121212;
  }
  .otp-form {
    margin-top: 36px;
  }
  .forgot-mobile {
    display: flex;
    justify-content: center;
    margin: 36px 0px;
  }
  .forgot-mobile a {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    letter-spacing: -0.3px;
    text-decoration-line: underline;
    color: #8f00ff;
  }
  .otp-input-box {
    width: 4px;
    height: 49px;
    border: none;
    background: #f7edff;
    border-radius: 8px;
  }
  .otp-parent {
    display: flex;
    justify-content: space-between;
  }
  .email-label {
    display: none;
  }
  .otp-button {
    margin-top: 24px;
  }

  .otpToSignUp {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    color: #757575;
  }
}
