.members-edit p {}

.dashboard-membersId-transactions-list {

    padding-left: 0px;

}

.resend-btn{
    display: flex;
    justify-content: center;
    align-items: center;
}

.resend-loader{
    width:15px;
}



.mb-1 {
    margin-bottom: 36px;
}

.mb-2 {
    margin-bottom: 10px !important;
}

.invitation-container {
    padding-bottom: 20px;
}

.dashboard-membersId-transactions-list p {
    width: 17.5vw;
    font-size: 14px;
    color: var(--default-links);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    height: 53px;




}

.dashboard-membersId-transaction-header-flex {
    display: flex;
    justify-content: space-between;
    background: #F8F8F8;
    padding: 24px 24px 13px 24px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20PX;

}

.dashboard-membersId-transaction-header-flex p {
    color: #757575;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    width: 17.5vw;
}

.members-btn-flex {
    display: flex;
    position: absolute;
    right: 5vw;
    cursor: pointer;
    top: 25px;
}

.members-btn {
    transform: translateY(20px);
    width: 146px;
    height: 45px;
    background-color: var(--primary-color);
    color: white;
    font-size: 14px;
    font-family: 'Poppins';
    border: none;
    cursor: pointer;
    margin-left: 20px;
    border-radius: 5px;
}

.modalid {
    background-color: #FFFFFF;

    position: absolute;
    z-index: 8000;
    left: calc((100% - 560px)/2);
    top: calc((100% - 530px) / 2);

    border-radius: 10px;
    width: 560px;
    height: 470px;

    -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}

.role-drop {
    width: 100vw;
    background-color: white;
    list-style: none;

    border-radius: 5px;

    height: auto;

   

    background: white;
    z-index: 1;
    border: 1px solid #C4C4C4;
    border-radius: 10px;
}

.role-drop li {
    padding: 10px 20px;

    transition: .1s ease-in-out;
}

.role-drop li:hover {
    color: white;


    transition: .1s ease-in-out;
}



.question-mark {
    background-color: transparent;
    width: 45px;
    border-radius: 50%;
    border: 1px solid #C4C4C4;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 14px;
}

.rolesModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 560px;
    height: 546px;

    background: #FFFFFF;
    border-radius: 10px;
    z-index: 10000;

    -webkit-animation: fade-in-fwd 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

}







.rolesModal-header {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-bottom: 1px solid #EFEFEF;
}




.rolesModal-header p {
    color: #757575;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.3px;
    margin-top: 36px;
    padding-bottom: 12px;
}

.rolesModal-body {
    padding: 36px;
    padding-bottom: 0px;
    overflow-y: auto;
    height: 370px;
}

.rolesModal-object-header {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #121212;
    margin-bottom: 24px;
}

.mt-36 {
    margin-top: 36px;
}


.rolesModal-object-body {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #757575;
    text-align: left;
}

.rolesModal-object-body {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #757575;
    text-align: left;
}

.rolesModal-close-btn {
    background: #F8F8F8;
    border-radius: 5px;
    width: 121px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #757575;
    cursor: pointer;
    border: 0;
    margin-left: 36px;
}

.mb-30 {
    padding-bottom: 10px;
}