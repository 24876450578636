

.dashboard-sidebar img{
    filter: invert(50%) sepia(8%) saturate(0%) hue-rotate(161deg) brightness(88%) contrast(80%);
    width: 20px;

}
.dashboard-sidebar-heading img{
    filter:  invert(0%);
    width: 40px;
}

.dashboard-sidebar{
    font-family: 'Poppins', sans-serif;
    width: 240px;
    background:  #FDFDFD;
    transition: .3s ease-in-out ;
    height: 100vh;
    
    position:sticky;
    top:0;
    z-index:0;   
}


.dashboard-sidebar-heading{
    margin: 40px 0px 70px 30px;


}
.dashboard-sidebar-tabs{
   
}

.dashboard-sidebar-links:hover p{
   
    color: var(--primary-color) ;
}
.dashboard-sidebar-links:hover img{
    filter: invert(0%);
}

.dashboard-sidebar-heading p:nth-child(1){
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px;
}
.dashboard-sidebar-heading p:nth-child(2){
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: var(--default-links);
}
.dashboard-sidebar-links{
    position: relative;
    width: 240px;
    padding-left: 30px;
    display: flex;
    padding-top: 5%;
    margin-top: 10%;
  
    z-index: 3000;
    
}

.dashboard-sidebar-line{
    width: 3px;
    height: 45px;
    background: var(--primary-color);
    border-radius: 10px;
    position: absolute;
    top: -1px;
    left: 0;
    display: none;
   
}
.dashboard-sidebar-links.active p{
    color: var(--primary-color);
}
.dashboard-sidebar-links.active img{
    filter: invert(0%);
}
.dashboard-sidebar-links.active .dashboard-sidebar-line{
    display: block;
    
}

.dashboard-sidebar-links p{
    color: var(--default-links);
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    margin-left: 14px;
    cursor: pointer;
   

}
.dashboard-sidebar-links p:hover{
    color: var(--primary-color);
}
.dashboard-sidebar-logout{
 position: absolute;
 bottom: 40px;
    margin-left: 30px;
}
.dashboard-sidebar-logout a{
    display: flex;
}
.dashboard-sidebar-logout img{
    filter: invert(0%);
}
.dashboard-sidebar-logout p{
    color: #FF3737;
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
    margin-left: 14px;
}

.logoutModal-container{
    position:absolute;
    left:50%;
    top:50%;
}



@media screen and (max-width: 975px) {
    .dashboard-sidebar{
        display: none;
    }
}