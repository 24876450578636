@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

@import url("https://fonts.cdnfonts.com/css/qanelas-soft");

:root {
  --primary-color: #8f00ff;
  --default-links: #757575;
  --black: #121212;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Qanelas Soft", sans-serif !important;
}

body {
  font-family: "Qanelas Soft", sans-serif;
  scrollbar-color: var(--primary-color);
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

::selection {
  background: #757575;
  color: #fff;
}

/* *::-webkit-scrollbar {
  width: 2px;
  height: 0px;
}



*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 20px;
 
} */

/* ::-webkit-scrollbar {
  display: block;
} */

input:-webkit-autofill::first-line {
  font-family: "Poppins";
}

.new-user-screen {
  display: none;
}

@media screen and (max-width: 975px) {
  body {
    display: block;
  }
}

@media screen and (max-width: 975px) {
  .new-user-screen {
    width: 100vw;
    background: #121212;
    height: 100vh;
    position: absolute;
    display: block;
    z-index: 1000;
  }

  .new-user-screen-fade-out {
    animation-name: fade-out;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }

  .not {
    display: none;
  }

  .new-user-screen-skip-btn {
    color: var(--default-links);
    position: absolute;
    right: 24px;
    top: 50px;
  }

  .new-user-screen-info {
    background: #f5f5f5;
    position: absolute;
    width: 100vw;
    height: 50vh;
    bottom: 0;
    display: flex;
    justify-content: center;
  }

  .new-user-screen-info-text {
    width: 90vw;
    margin-top: 36px;
  }

  .new-user-screen-info-mainText {
    color: #121212;
    font-size: 32px;
    font-weight: 600;
    width: 100%;
  }

  .new-user-screen-info-subText {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: -0.3px;
    margin-top: 24px;
    color: var(--default-links);
  }

  .new-user-screen-info-faq {
    color: #8f00ff;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    text-align: center;
    position: absolute;
    bottom: 37%;
    left: 50%;
    transform: translateX(-50%);
  }
  .new-user-screen-info-faq a {
    text-decoration: none;
  }

  .new-user-screen-info-controls {
    margin-top: 40px;
    height: 60px;
    position: absolute;
    width: 90vw;
    bottom: 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .new-user-screen-dots {
    display: flex;
    width: 60px;
    gap: 4px;
  }

  .control-dot {
    width: 6px;
    height: 6px;
    background: #d9d9d9;
    border-radius: 50%;
    transition: all 0.4s ease;
  }

  .active-dot {
    width: 24px;
    background: #121212;
    border-radius: 6px;
    /* animation-name: active-dot;
    animation-duration: .5s;
    animation-timing-function: linear; */
    transition: all 0.4s ease;
  }

  .new-user-screen-next-btn {
    width: 50px;
    height: 50px;
    background: #121212;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    color: #fff;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .purple-new-user-faq {
    color: #8f00ff;
  }

  .new-user-screen-next-btn img {
    width: auto;
  }

  .new-user-screen-gif img {
    width: 220px;
    height: 220px;
    /* background:red; */
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 14%;
  }

  .fade-out-new-user {
    -webkit-animation: fade-out 0.5s ease-out both;
    animation: fade-out 0.5s ease-out both;
  }

  @-webkit-keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@keyframes removeNewScreen {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

@media screen and (max-height: 690px) {
  .new-user-screen-info-faq {
    bottom: 30%;
  }
  .new-user-screen-gif {
    top: 10%;
  }

  .new-user-screen-info-controls {
    bottom: 10%;
  }
}

@media screen and (max-width: 975px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* PRELOADER */

/* preloader */

.preloaderContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preLoader img {
  width: 60px;
  height: auto;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.puff-out-center {
  -webkit-animation: puff-out-center 300ms cubic-bezier(0.165, 0.84, 0.44, 1)
    both;
  animation: puff-out-center 300ms cubic-bezier(0.165, 0.84, 0.44, 1) both;
}

@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
