.status{
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    
}
.dashboard-payouts-container{
    padding: 40px 48px 68px 48px;
    padding-bottom:0px;
    width: 100%;
    transition: .3s ease-in-out;
    z-index: 2000;
    
}

.res-p{
 position: absolute;
 transform: translateY(5px);
}
.withdraw-modal{
    width:676px !important;
    height:549px !important;
    
    padding:0px;
}

.dashboard-modal-buttons{
    display: flex;
    justify-content: space-between;
  padding-left: 36px;
    padding-right: 36px;
   position: absolute;
   bottom: 30px;
  width: 100%;
}
.payouts-warning{
    background: #FFFDEC;
    
    margin-left: 38px;
  margin-right: 38px;
  border-radius: 5px;
  margin-top: 16px;
  height: 64.21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height:auto;

}
.payouts-warning p{
  font-size: 14px;
  color: #BEA802;
  padding: 10px;
  
}

.payouts-modal-content{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-left:38px;
    margin-right:38px;
    margin-top:36px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color:#757575;
}

.payouts-modal-accounts-right{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}

.payouts-modal-account,
.payouts-modal-balance,
.payouts-modal-fee{
    display:flex;
    justify-content:space-between;
}
.payouts-modal-account,
.payouts-modal-fee{
   margin-top:24px;
}

.payouts-modal-balance-right,
.payouts-modal-fee-right,
.payouts-modal-accounts-right
{
    font-family: 'Poppins';
    
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
}


.dashboard-modal-payouts-input{
    
    margin-top: 36px;
    margin-bottom: 20px;
}
.dashboard-modal-payouts-input label{
    display: block;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    margin-top: 15px;
    letter-spacing: -0.3px;
    color: #757575;
}
.dashboard-modal-payouts-input input{
    width: 100%;
    height: 41px;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
    margin-top:22px;
  
    
    outline-color:var(--primary-color);
    outline-width: 0.3px;

    font-family: 'Poppins' ,sans-serif;
    padding-left: 20px;
}
.dashboard-modal-payouts-input input:nth-child(6){
    width: 100%;
    height: 80px;
    border: 1px solid #EFEFEF;
    border-radius: 5px;

    
    outline-color:var(--primary-color);
    outline-width: 0.3px;

    font-family: 'Poppins' ,sans-serif;
    padding-left: 20px;
}


.form-payouts{
    width:100%;
    display:flex;
    justify-content: space-between;
    margin-top:48px;

}

.payouts-modal-cancel{
    padding:12px 36px;
    border:0;
    border-radius:5px;
    background: #F8F8F8;
    cursor:pointer;

}

.payouts-modal-cancel p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: -0.3px;
    color: #757575;
}

.payouts-modal-submit{
    width: 121px;
    height: 45px;
    border:0;
    border-radius:5px;
    background: var(--primary-color);
    cursor:pointer;
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: -0.3px;
    color: #757575;
}


.load{
    position: absolute;
}
.payouts-modal-submit p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    letter-spacing: -0.3px;
    color: #FFFFFF;
}







.dashboard-payouts-navbar{
    display: flex;
    justify-content: space-between;
}




.dashboard-payouts-navbar-left p:nth-child(1){
    color: var(--black);
    font-size: 20px;
    line-height: 31px;
    font-weight: 500;
    letter-spacing: -0.3px;
}
.dashboard-payouts-navbar-left p:nth-child(2){
    color: var(--default-links);
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: -0.3px;
}

.dashboard-payouts-navbar-right button{
    background: var(--primary-color);
    color:#fafafa;
    padding: 12px 36px;
    border-radius:5px;
    cursor:pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    outline: 0;
    border:0;
    font-family: 'Poppins';
}


.dashboard-payouts-transactions{
  
   
    /* padding-top: 25px;
    padding-bottom: 25px; */
    background: #FFFFFF;
    padding-bottom:25px;
    border-radius: 5px;
    margin-top:60px;
    border: 1px solid #D9D9D9;
    border-radius: 20px;
    /* min-height:75vh; */
    overflow-y: hidden;

    
    /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(32, 32, 32, 0.05); */
    /* height:auto !important; */
    
}
.dashboard-payouts-transaction-nav{
    display: flex;
    justify-content: space-between;
    margin: 60px 0px 48px 0px;
}
.dashboard-payouts-transaction-nav-heading{
    display: flex;
}

.dashboard-payouts-transaction-nav p{
    color: var(--default-links);
}
.dashboard-payouts-transaction-nav-heading p{
    color: var(--black);
    font-size: 15px;
}
.dashboard-payouts-transaction-nav-heading img{
    margin-right: 18px;
}

.dashboard-payouts-fetch-flex:nth-child(1){
    border-top: none;
}
.dashboard-payouts-fetch-flex{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #EFEFEF;
    cursor: pointer;
    transition: background  300ms  ease-in-out;
    padding:0px 24px;
}
.dashboard-payouts-fetch-flex:hover{
    background: #F8F8F8;
    transition: background  300ms ease-in-out;
}


.dashboard-payouts-transactions-list{
    
    padding-left: 0px;
}

.dashboard-payouts-transactions-list p{
    width: 14vw;
    font-size: 14px;
    color: var(--default-links);
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 500;
    height: 53px;
   
   
    
  
}
.dashboard-payouts-transaction-header-flex{
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 13px 24px;
    /* margin-bottom: 12px; */
    background:#F8F8F8;
    border-top-right-radius:20px;
    border-top-left-radius: 20px;
   
}
.dashboard-payouts-transaction-header-flex p{
    color: #757575;
    font-size: 13px;
    line-height: 21px;
    font-weight: 500;
    width: 14vw;
}


