.orgranisationId{
    padding: 40px 5% 68px 48px;
    width: 100%;
    transition: .3s ease-in-out;
    z-index: 2000;
    
}
.orgranisationId-line-container{
    margin: 65px 0px 0px 0px;
    padding-left: 20px;
    padding-bottom: 18px;
}


.organisationId-line-items{
    display: flex;
    justify-content: space-between;
    width: 600px;
}
.organisation-line-box{
    position: relative;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.organisation-line-box p{
    
    text-align: center;
    position: absolute;
    margin-bottom: 20px;


    font-size:15px;
    color:#C4C4C4;
    cursor:pointer;
    transition:color 250ms ease-in-out;
}
.organisationId-line{
    height: 2px;
    width: 30px;
    background: var(--primary-color);
    border-radius: 20px;
    transform: translateY(18px);
}
  
.organisations-border-bottom{
    border-bottom:1px solid #F8F8F8;
    margin-left:-48px;
    width: calc(100vw - 240px);
    /* position: absolute; */
   
   
}


.dashboard-organisationid-navbar-left{
    display:flex;
    justify-content: flex-start;
    align-items: center;
}

.organisation-branch-name{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color:var(--black);
}
.organisation-branch-id{
    color:var(--default-links) ;
    font-family: 'Poppins' ;
    font-style: normal ;
    font-weight: 400 ;
    font-size: 13px;
    line-height: 23px ;
}

.organisationid-back{
    margin-right:24px;
    width:36px;
    height:36px;
    cursor:pointer;
}