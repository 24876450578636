.pop-container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
   
   
}
.border-top{
    display: flex;
    justify-content: center;
    margin: 0px 0px;
   position: fixed;
   background-color: white;
   width: 100%;
   left: 0;
   padding: 24px 0px 5px 0px;
   border-radius: 30px 30px 0px 0px;
}
.pop-white{
    z-index: 21000;
    position: absolute;
    width: 100%;
    height: 65vh;
    overflow-y: auto;
    background:  white;
    border-radius: 30px 30px 0px 0px;
    padding: 0px 24px 24px 24px;
    bottom: 0vh;
    left: 0;
    -webkit-animation: slide-in-bottom 0.3s ;
	  animation: slide-in-bottom 0.3s ;
    border-bottom: 1px solid #D9D9D9;
        
}

.leave{
  z-index: 21000;
    position: absolute;
    width: 100%;
    height: 65vh;
    overflow-y: auto;
    background:  white;
    border-radius: 30px 30px 0px 0px;
    padding: 0px 24px 24px 24px;
    bottom: -20vh;
    left: 0;
    -webkit-animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-19 16:36:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 20:6:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
.slide-out-bottom {
    display: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-4 20:5:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  
 