.dashboard-organisations-container{
    padding: 40px 5% 0px 48px;
    width: 100%;
    transition: .3s ease-in-out;
    z-index: 2000;
    
}


.dashboard-organisations-navbar{
    display: flex;
    justify-content: space-between;
}

.dashboard-organisations-navbar-left p:nth-child(1){
    color: var(--black);
    font-size: 20px;
    line-height: 31px;
    font-weight: 500;
    letter-spacing: -0.3px;
}
.dashboard-organisations-navbar-left p:nth-child(2){
    color: var(--default-links);
    font-size: 13px;
    line-height: 23px;
    font-weight: 400;
    letter-spacing: -0.3px;
}
.dashboard-organisation-navbar-right{
    align-self:center;
}
.dashboard-organisation-navbar-right button{
    color: white;
    background: #8F00FF;
    border: none;
    width: 146px;
    height: 45px;
    border-radius: 5px;
    font-family: 'poppins', sans-serif;
    margin-left: 48px;
    cursor: pointer;
    
}
.dashboard-organisation-navbar-right button:nth-child(1){
    color: #757575;
    background: #F8F8F8;
    border: none;
    width: 146px;
    height: 45px;
    border-radius: 5px;
    font-family: 'poppins', sans-serif;
    
}

.dashboard-organisation-navbar-right img{
    transform: translateY(03px);
    align-self: center;
    width: 15px;
   
}
.dashboard-modal-organisation-input{
    margin-left: 36px;
    margin-right: 36px;
    margin-top: -10px;
    margin-bottom: 20px;
}
.dashboard-modal-organisation-input label{
    display: block;
    margin-bottom: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    margin-top: 15px;
    letter-spacing: -0.3px;
    color: #757575;
}
.dashboard-modal-organisation-input input{
    width: 100%;
    height: 38px;
    border: 1px solid #EFEFEF;
    border-radius: 5px;
  
    
    outline-color:var(--primary-color);
    outline-width: 0.3px;

    font-family: 'Poppins' ,sans-serif;
    padding-left: 20px;
}
.dashboard-modal-organisation-input input:nth-child(6){
    width: 100%;
    height: 80px;
    border: 1px solid #EFEFEF;
    border-radius: 5px;

    
    outline-color:var(--primary-color);
    outline-width: 0.3px;

    font-family: 'Poppins' ,sans-serif;
    padding-left: 20px;
}

#invites-text{
    background: #FFFDEC;
    margin-left: 36px;
    margin-right: 36px;
    border-radius: 5px;
    margin-top: 28px;
    height: 30px;
    display: flex;
    justify-content: left;
    position: relative;
}
#invites-text p{
    position: absolute;
    top: -3px;
    left: 10px;
}
.invitation-flex-container{
    display: flex;
    justify-content: space-between;
    padding: 28px 36px 0px 36px;
}
.invitation-left{
    display: flex;
    align-self: center;
}
/* .invitation-right button:nth-child(1){
    font-family: 'Poppins', sans-serif;
    border: none;
    background: #F8F8F8;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
} */
.invitation-right button{
    font-family: 'Poppins', sans-serif;
    color: white;
    border: none;
    background: var(--primary-color);
    width: 100px;
    height: 35px;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}
.invite-cirle{
    background: #EDFCEF;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.invite-cirle p{
    color: var(--black);
    font-size: 16px;
}
.invite-text p:nth-child(1){
    font-size: 16px;
    color: var(--black);
}
.invite-text p:nth-child(2){
    font-size: 12px;
    color: var(--default-links);
}
.invitation-container{
    height: 300px;
    overflow-y: auto;
}
.loa{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    width: 300px;
    height: 50%;
    margin: 50px auto;
  
}