.settings-outlets-warning {
    margin-top:36px;
    background: #FFFCE9;
    width:520px;
    padding:14px;
    
}

.settings-outlets-warning p{
    color: #BEA802;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    
}

.cac-container{
    position: relative;
}


.cac-doc{
    
    cursor:pointer;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);


}

.cac-doc p{
    color: #C4C4C4;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}

.cac-container {
    width:528px;
    height:41px;
    background-image: url("/public/assets/dashed-border.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
    cursor:pointer;
    margin-top:24px;
    
}