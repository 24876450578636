.setting-profile-form{
    margin-top:35px;
}

.label{
    color:#757575;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.3px;
    margin-top:36px;
    /* margin-bottom:1rem; */
}



.settingsInputBox{
    width: 528px !important;
    height: 41px !important;
    padding-left:20px;
    color:var(--black);
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    border:1px solid rgba(229,231,235);
    margin-top:13px;
    border-radius:5px;
}

.setting-profile-form input:focus{
    outline:1px solid var(--primary-color);
    border:none;

}


.profile-save-button{
    width:160px;

    height:40px;
    margin-top:48px;
    background-color:var(--primary-color);
    color:#fff;
    border:0;
    border-radius:5px;
    font-size:14px;
    font-weight:500;
    font-family:"Poppins" , sans-serif;
}

.profile-loading-save-button{
    width:160px;
    height:40px;
    margin-top:48px;
    background-color:#F8F8F8;
    color:#757575;
    border:0;
    border-radius:5px;
    font-family:"Poppins" , sans-serif;
}

.profile-loading-save-button img{
    width:40px;

}

.settingEmailError{
    margin-top:35px;
}

#modalpass{
    background-color: #FFFFFF;
   
    position: absolute;
    left: calc(50% - 510px);
    top: 20%;
    border-radius: 10px;
    width: 530px;
    height: 520px;
}