.mobile-transactions-top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.m-transactions-top-left {
  align-self: center;
}
.m-transactions-top-left h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.3px;
  color: #121212;
  align-self: center;
}
.m-transactions-top-right {
  display: flex;
  gap: 12px;
}
.mobile-search {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f8f8f8;
  margin-bottom: 24px;
}
.mobile-search input {
  border: none;
  outline: none;
  outline: none;
  height: 100%;
  width: 80%;
  height: 25px;
}
.mobile-search input::placeholder {
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #c4c4c4;
}
.pop-start-end {
  display: flex;
  justify-content: space-between;
}
.pop-start-end input {
  outline: none;
  width: 45%;

  left: 24px;
  top: 222px;
  background: #f8f8f8;

  padding-left: 12px;
  border: none;
}
.pop-label-input {
  margin-top: 24px;
}
.separa p {
  background: #757575;
  height: 25px;
  width: 1px;
  transform: translate(0px, -10px);
  transform: rotate(190deg);
}
.pop-label-input label {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #757575;
}
.pop-label-input input {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  height: 49px;
  width: 100%;
  padding: 16px;
  margin-top: 6px;
  outline: none;
}
.pop-label-input input::placeholder {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.3px;
  color: #757575;
}
.pop-body button {
  height: 54px;
  background: #d9d9d9;
  border-radius: 12px;
  width: 100%;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.3px;

  /* white/main */

  color: #ffffff;
  margin-top: 36px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
